import styled from '@emotion/styled'
import { memo } from 'react'

import { HikerUserRole } from '@contracts/types/HikerUser'

import { Colors } from '@pure/assets/Colors'

import Box from '@my-drifter/components/Box'
import FigmaText from '@my-drifter/components/FigmaText'
import FullScreenSpinner from '@my-drifter/components/FullScreenSpinner'
import Layout from '@my-drifter/components/Layout'
import RevenueReportTable from '@my-drifter/components/RevenueReportTable'
import useAppState from '@my-drifter/hooks/useAppState'
import { useUser } from '@my-drifter/hooks/useUser'
import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

import NotAuthorizedScreen from './NotAuthorizedScreen'

const RevenueScreen = () => {
  const { state } = useAppState()
  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)

  const isAllowed =
    !isLoadingUser &&
    user?.role === HikerUserRole.ADMIN &&
    user?.siteIds &&
    user?.siteIds?.length > 0 &&
    (user?.id === 55653 ||
      user?.id === 55736 ||
      user?.id === 55205 ||
      user?.id === 57186 ||
      user?.id === 8388 ||
      user?.id === 56986)

  if (!isAllowed && !isLoadingUser) return <NotAuthorizedScreen />

  return !isLoadingUser ? (
    <Layout isRevenueScreen>
      <Box fullWidth align="center" fullPadding spacing={Spacings.m}>
        <RevenueReportTable />
        <Box bottom spacing={Spacings.l}>
          <FigmaText textKey={Texts.textMyPortalPageHeaderStatistics} text="Payments for this month" />
        </Box>
        <Box fullWidth left right spacing={Spacings.xxxl} gap={Spacings.s}>
          <FigmaText textKey={Texts.textMyPortalConnectedVehiclesHeader} text="Parking Revenues" />
          <Box fullWidth direction="row" align="center" justify="space-between">
            <FigmaText
              textKey={Texts.textMyPortalStatsStatisticsColumnDate}
              text="Parking revenue to be paid this month"
            />
            <Bold>
              <FigmaText textKey={Texts.textMyPortalStatsStatisticsColumnDate} text="3.500 SEK" />
            </Bold>
          </Box>
          <Box fullWidth direction="row" align="center" justify="space-between">
            <FigmaText
              textKey={Texts.textMyPortalStatsStatisticsColumnDate}
              text="Parking revenue to be paid next month (to date)"
            />
            <FigmaText textKey={Texts.textMyPortalStatsStatisticsColumnDate} text="2.500 SEK" />
          </Box>
          <Box fullWidth top bottom gap={Spacings.s}>
            <FigmaText textKey={Texts.textMyPortalConnectedVehiclesHeader} text="Parking Permit" />
            <Box fullWidth direction="row" align="center" justify="space-between">
              <FigmaText
                textKey={Texts.textMyPortalStatsStatisticsColumnDate}
                text="Parking permit revenue to be paid this month"
              />
              <Bold>
                <FigmaText textKey={Texts.textMyPortalStatsStatisticsColumnDate} text="9.800 SEK" />
              </Bold>
            </Box>
            <Box fullWidth direction="row" align="center" justify="space-between">
              <FigmaText
                textKey={Texts.textMyPortalStatsStatisticsColumnDate}
                text="Parking permit revenue to be paid next month (to date)"
              />
              <FigmaText textKey={Texts.textMyPortalStatsStatisticsColumnDate} text="7.500 SEK" />
            </Box>
          </Box>
          <YellowBox fullWidth direction="row" align="center" justify="space-between" fullPadding spacing={Spacings.s}>
            <FigmaText textKey={Texts.textMyPortalStatsStatisticsColumnDate} text="Amount to be paid out this month" />
            <Bold>
              <FigmaText textKey={Texts.textMyPortalStatsStatisticsColumnDate} text="13.300 SEK" />
            </Bold>
          </YellowBox>
        </Box>
      </Box>
    </Layout>
  ) : (
    <FullScreenSpinner />
  )
}

const Bold = styled(Box)`
  span {
    font-weight: 800 !important;
  }
`

const YellowBox = styled(Box)`
  background-color: ${Colors.yellow0};
`

export default memo(RevenueScreen)
