import styled from '@emotion/styled'
import { memo } from 'react'

import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'

import { Colors } from '@pure/assets/Colors'

import ZIndices from '@web-js/enums/Zindices'

import Box from '@my-drifter/components/Box'
import CalendarInput from '@my-drifter/components/CalendarInput'
import DropdownSelector from '@my-drifter/components/DropdownSelector'
import FigmaText from '@my-drifter/components/FigmaText'
import FullScreenSpinner from '@my-drifter/components/FullScreenSpinner'
import Layout from '@my-drifter/components/Layout'
import SessionOverviewTable from '@my-drifter/components/SessionOverviewTable'
import useSessionScreenData from '@my-drifter/hooks/useSessionScreenData'
import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

import NotAuthorizedScreen from './NotAuthorizedScreen'
import NotAvailableOnMobileScreen from './NotAvailableOnMobileScreen'

const SessionsScreen = () => {
  const {
    isAllowed,
    isLoadingUser,
    isDesktop,
    selectedSiteId,
    setSelectedSiteId,
    isLoadingData,
    sessionsData,
    calendarValue,
    setCalendarValue,
    dataTypeOptions,
    selectedDataType,
    setSelectedDataType
  } = useSessionScreenData()

  if (!isAllowed && !isLoadingUser) return <NotAuthorizedScreen />

  if (!isDesktop) return <NotAvailableOnMobileScreen />

  return !isLoadingUser ? (
    <Layout isSessionsScreen selectedSiteId={selectedSiteId} setSelectedSiteId={setSelectedSiteId}>
      <Box fullWidth align="center" bottom spacing={Spacings.xxxl}>
        {selectedSiteId && (
          <Box fullWidth align="center" bottom zIndex={ZIndices.higher}>
            <CalendarInput value={calendarValue} onChange={setCalendarValue} />
          </Box>
        )}
        {selectedSiteId && (
          <GreyContainer fullWidth bottom spacing={Spacings.m}>
            <Box fullWidth align="center" top bottom>
              <FigmaText textKey={Texts.textMyPortalPageHeaderStatistics} />
            </Box>
            {!isLoadingData && sessionsData.length !== 0 && (
              <Box fullWidth align="flex-end" right zIndex={ZIndices.higher}>
                <DropdownSelector
                  dropdownType="month"
                  options={dataTypeOptions}
                  selected={selectedDataType}
                  onSelect={(value) => setSelectedDataType(value as 'count' | 'value')}
                />
              </Box>
            )}
            <SessionOverviewTable
              data={sessionsData}
              isLoadingData={isLoadingData}
              selectedDataType={selectedDataType}
            />
          </GreyContainer>
        )}
        {!selectedSiteId && (
          <CenteredText>
            <FigmaText textKey={Texts.textMyPortalSessionReportEmptyState} />
          </CenteredText>
        )}
      </Box>
    </Layout>
  ) : (
    <FullScreenSpinner />
  )
}

const CenteredText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 80vh;
  align-items: center;
  justify-content: center;
`

const GreyContainer = styled(Box)`
  max-width: 90%;
  background-color: ${Colors.neutral0};
  border-radius: ${BorderRadiusesPx.minimum};
`

export default memo(SessionsScreen)
