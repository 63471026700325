import styled from '@emotion/styled'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { memo, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { TextKey } from '@pure/emu/Antiloop'

import { useIsDesktop } from '@my-drifter/hooks/useIsMobile'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import FigmaText from './FigmaText'

interface Props {
  options?: {
    icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
      muiName: string
    }
    textKey: TextKey
    path: string
  }[]
  selected?: {
    textKey?: TextKey
    icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
      muiName: string
    }
  }
}

const DropdownMenuSelector = ({ options, selected }: Props) => {
  const isDesktop = useIsDesktop()
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef<HTMLUListElement>(null)
  const triggerRef = useRef(null)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      if (event.target !== triggerRef.current) {
        setIsOpen(false)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isOpen])

  return (
    <DropdownContainer>
      <DropdownTrigger onClick={handleClick} $isdesktop={isDesktop}>
        <Box ref={triggerRef} fullWidth direction="row" align="center" justify="space-between">
          <Box fullWidth direction="row" align="center" top spacing={Spacings.xxs} gap={Spacings.xs}>
            {selected?.icon && <selected.icon />}
            {selected?.textKey && <FigmaText textKey={selected?.textKey} />}
          </Box>
          <ExpandMore />
        </Box>
      </DropdownTrigger>
      <DropdownMenu ref={menuRef} className={isOpen ? '' : 'hidden'} $isdesktop={isDesktop}>
        {options?.map((option) => (
          <DropdownOption key={option.path}>
            <Link to={option.path}>
              <FigmaText textKey={option.textKey} />
            </Link>
          </DropdownOption>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  )
}

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: ${Spacings.xs};
`

const DropdownTrigger = styled.div<{ $isdesktop: boolean }>`
  font-family: 'Inter';
  width: ${(props) => (props.$isdesktop ? '520px' : '94%')};
  height: 30px;
  background: none;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  user-select: none;

  &:hover {
    background-color: #f5f5f5;
  }
`

const DropdownMenu = styled.ul<{ $isdesktop: boolean }>`
  position: absolute;
  width: ${(props) => (props.$isdesktop ? '520px' : '100%')};
  top: 100%;
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 999 !important;

  &.hidden {
    display: none;
  }
`

const DropdownOption = styled.li`
  font-family: 'Inter';
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`

export default memo(DropdownMenuSelector)
