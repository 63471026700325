import styled from '@emotion/styled'
import { memo } from 'react'

import { Colors } from '@pure/assets/Colors'

import revenueData from '@my-drifter/assets/revenueReportData.json'
import { formatNumberWithDot } from '@my-drifter/libs/CommonHelper'
import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import FigmaText from './FigmaText'

const RevenueReportTable = () => {
  return (
    <Container fullWidth align="center" fullPadding spacing={Spacings.m}>
      <FigmaText textKey={Texts.textMyPortalPageHeaderStatistics} text="Revenue from the last 6 months" />
      <Table>
        <thead>
          <tr>
            <Th>
              <FigmaText textKey={Texts.textMyPortalStatsStatisticsColumnDate} />
            </Th>
            <Th>
              <FigmaText textKey={Texts.textMyPortalStatsStatisticsColumnPermits} text="Parking" />
            </Th>
            <Th>
              <FigmaText textKey={Texts.textMyPortalStatsStatisticsColumnPermits} text="Paid" />
            </Th>
            <Th>
              <FigmaText textKey={Texts.textMyPortalStatsStatisticsColumnPermits} text="Not Paid" />
            </Th>
            <Th isBold>
              <FigmaText textKey={Texts.textMyPortalStatsStatisticsColumnPermits} text="To Be Paid" />
            </Th>
            <Th>
              <FigmaText textKey={Texts.textMyPortalStatsStatisticsColumnDate} text="Permit" />
            </Th>
            <Th>
              <FigmaText textKey={Texts.textMyPortalStatsStatisticsColumnDate} text="Paid" />
            </Th>
            <Th>
              <FigmaText textKey={Texts.textMyPortalStatsStatisticsColumnDate} text="Not Paid" />
            </Th>
            <Th isBold>
              <FigmaText textKey={Texts.textMyPortalStatsStatisticsColumnDate} text="To Be Paid" />
            </Th>
          </tr>
        </thead>
        <tbody>
          {revenueData.map((row) => {
            if (row?.status === 'Published')
              return (
                <tr key={row.Date}>
                  <Td>
                    <FigmaText
                      textKey={Texts.textMyPortalStatsStatisticsColumnDate}
                      text={`${new Date(row.Date).getFullYear()} ${new Date(row.Date).toLocaleString('en-UK', { month: 'long' })}`}
                    />
                  </Td>
                  <Td>
                    <FigmaText
                      textKey={Texts.textMyPortalStatsStatisticsColumnPermits}
                      text={formatNumberWithDot(row.ParkingRevenue)}
                    />
                  </Td>
                  <Td>
                    <FigmaText
                      textKey={Texts.textMyPortalStatsStatisticsColumnPermits}
                      text={formatNumberWithDot(row.ParkingRevenuePaid)}
                    />
                  </Td>
                  <Td>
                    <FigmaText
                      textKey={Texts.textMyPortalStatsStatisticsColumnPermits}
                      text={formatNumberWithDot(row.ParkingRevenuePayout)}
                    />
                  </Td>
                  <Td isBold>
                    <FigmaText
                      textKey={Texts.textMyPortalStatsStatisticsColumnPermits}
                      text={formatNumberWithDot(row.ParkingRevenuePendingPayout)}
                    />
                  </Td>
                  <Td>
                    <FigmaText
                      textKey={Texts.textMyPortalStatsStatisticsColumnDate}
                      text={formatNumberWithDot(row.PermitRevenue)}
                    />
                  </Td>
                  <Td>
                    <FigmaText
                      textKey={Texts.textMyPortalStatsStatisticsColumnDate}
                      text={formatNumberWithDot(row.PermitRevenuePaid)}
                    />
                  </Td>
                  <Td>
                    <FigmaText
                      textKey={Texts.textMyPortalStatsStatisticsColumnDate}
                      text={formatNumberWithDot(row.PermitRevenuePayout)}
                    />
                  </Td>
                  <Td isBold>
                    <FigmaText
                      textKey={Texts.textMyPortalStatsStatisticsColumnDate}
                      text={formatNumberWithDot(row.PermitRevenuePendingPayout)}
                    />
                  </Td>
                </tr>
              )
          })}
        </tbody>
      </Table>
    </Container>
  )
}

const Container = styled(Box)`
  margin-bottom: ${Spacings.xxxl};
  background-color: ${Colors.gray50};
  border: 1px solid ${Colors.gray200};
`

const Table = styled.table`
  width: 100%;
  padding-top: ${Spacings.m};
`

const Th = styled.th<{ isBold?: boolean }>`
  text-align: left;
  padding: ${Spacings.xs};
  background-color: ${Colors.white};

  span {
    font-weight: ${({ isBold }) => (isBold ? 800 : 'normal')} !important;
  }
`

const Td = styled.td<{ isBold?: boolean }>`
  text-align: left;
  padding: ${Spacings.s} ${Spacings.xs};

  span {
    font-weight: ${({ isBold }) => (isBold ? 800 : 'normal')} !important;
  }
`

export default memo(RevenueReportTable)
