import styled from '@emotion/styled'
import ArticleIcon from '@mui/icons-material/Article'
import DashboardIcon from '@mui/icons-material/Dashboard'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart'
import { memo } from 'react'
import { Link } from 'react-router-dom'

import { HikerUserRole } from '@contracts/types/HikerUser'

import { Colors } from '@pure/assets/Colors'

import ZIndices from '@web-js/enums/Zindices'

import Texts from '@my-drifter/assets/texts.json'
import useAppState from '@my-drifter/hooks/useAppState'
import { useIsDesktop } from '@my-drifter/hooks/useIsMobile'
import { useLogout } from '@my-drifter/hooks/useLogout'
import { useUser } from '@my-drifter/hooks/useUser'
import Images from '@my-drifter/libs/Images'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import DropdownMenuSelector from './DropdownMenuSelector'
import FigmaImage from './FigmaImage'
import FigmaText from './FigmaText'

const LeftNavigation = () => {
  const isDesktop = useIsDesktop()
  const logOut = useLogout()
  const url = window.location.pathname
  const { state } = useAppState()
  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)

  const navigationItems =
    !isLoadingUser && user?.role === HikerUserRole.ADMIN && user?.siteIds?.length !== 0
      ? [
          { icon: DashboardIcon, textKey: Texts.textMyPortalMenuDashboard, path: '/dashboard' },
          { icon: StackedBarChartIcon, textKey: Texts.textMyPortalMenuSessions, path: '/sessions' },
          { icon: RequestQuoteIcon, textKey: Texts.textMyPortalMenuRevenue, path: '/revenue' },
          { icon: ArticleIcon, textKey: Texts.textMyPortalMenuPermit, path: '/permits' }
        ]
      : [{ icon: ArticleIcon, textKey: Texts.textMyPortalMenuPermit, path: '/permits' }]

  const getSelectedItemTextKeyAndIcon = () => {
    const selectedItem = navigationItems.find((item) => item.path === url)
    return { textKey: selectedItem?.textKey, icon: selectedItem?.icon }
  }

  return isDesktop ? (
    <StyledNavigation>
      <Box bottom spacing={Spacings.xxxl}>
        <FigmaImage imageKey={Images.drifterLogoDark} />
      </Box>
      {navigationItems.map((navigationItem) => (
        <StyledNavigationLink
          key={navigationItem.textKey.name}
          to={navigationItem.path}
          isActive={navigationItem.path === url}
        >
          <navigationItem.icon />
          <FigmaText textKey={navigationItem.textKey} />
        </StyledNavigationLink>
      ))}
      <StyledLogoutButton fullWidth align="center" onClick={logOut}>
        <FigmaText textKey={Texts.textMenuUserMenuUserMenuLogout} />
      </StyledLogoutButton>
    </StyledNavigation>
  ) : (
    <DropdownMenuSelector options={navigationItems} selected={getSelectedItemTextKeyAndIcon()} />
  )
}

const StyledNavigation = styled.nav`
  top: 0;
  left: 0;
  width: 230px;
  height: 100vh;
  padding: 20px;
  display: flex;
  position: fixed;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #f0ebf5;
  z-index: ${ZIndices.heaven};
  transition: all 0.2s ease-in-out;
`

const StyledNavigationLink = styled(Link)<{ isActive: boolean }>`
  display: flex;
  color: #333;
  padding: ${Spacings.xs};
  cursor: pointer;
  margin-bottom: 5px;
  align-items: center;
  gap: ${Spacings.xs};
  font-family: 'Inter';
  text-decoration: none;
  border: ${(props) => (props.isActive ? `2px solid ${Colors.primary}` : '2px solid transparent')};
  border-radius: ${Spacings.xs};
  background-color: ${(props) => (props.isActive ? '#fff' : 'transparent')};

  :hover {
    border-color: ${Colors.primary};
  }
`

const StyledLogoutButton = styled(Box)`
  border: none;
  cursor: pointer;
  margin-top: auto;
  padding: 10px 20px;
  text-decoration: underline;
  border-radius: ${Spacings.xs};
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #fff;
  }
`

export default memo(LeftNavigation)
