import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { memo } from 'react'

import { Colors } from '@pure/assets/Colors'
import { TextKey } from '@pure/emu/Antiloop'

import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import FigmaText from './FigmaText'

interface Props {
  data: Array<{ [key: string]: any }>
  isLoadingData: boolean
  selectedDataType: 'count' | 'value'
}

const SessionOverviewTable = ({ data, isLoadingData, selectedDataType }: Props) => {
  if (isLoadingData) {
    return (
      <Box fullWidth align="center" top spacing={Spacings.l}>
        <Spinner />
      </Box>
    )
  }

  if (data.length === 0) {
    return (
      <Box fullWidth align="center" top bottom spacing={Spacings.l}>
        <FigmaText textKey={Texts.textMyPortalErrorSessionOverviewData} />
      </Box>
    )
  }

  const headers = Object.keys(data[0])

  const getHeaderTextKeys = (header: string): TextKey => {
    switch (header) {
      case 'day':
        return Texts.textMyPortalStatsStatisticsColumnDate
      case 'countOfSessions':
        return Texts.textMyPortalStatsStatisticsColumnSessions
      case 'averageSessionTimeInMinutes':
        return Texts.textMyPortalStatsStatisticsColumnAverageTime
      case 'countOfSessionsWithPermits':
        return Texts.textMyPortalStatsStatisticsColumnPermits
      case 'countOfPaidSessions':
        return Texts.textMyPortalStatsStatisticsColumnPaidSession
      case 'countSessionsEvOffense':
        return Texts.textMyPortalStatsStatisticsColumnOffenseEv
      case 'countSessionsDisabledOffense':
        return Texts.textMyPortalStatsStatisticsColumnOffenseDisabled
      case 'countSessionsMarkedOffense':
        return Texts.textMyPortalStatsStatisticsColumnOffenseMarked
      default:
        return null as any as TextKey
    }
  }

  return (
    <Box fullWidth left right>
      <StyledTable>
        <thead>
          <tr>
            {headers.map((header) => (
              <StyledTh key={header}>
                <FigmaText textKey={getHeaderTextKeys(header) as TextKey} />
              </StyledTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => {
            const isFuture = dayjs(row['day']).isAfter(dayjs(), 'day')
            return (
              <tr key={rowIndex}>
                {headers.map((header) => {
                  const isFutureDate = isFuture
                  if (header === 'day') {
                    return (
                      <StyledTd key={header} isFutureDate={isFutureDate}>
                        <FigmaText textKey={getHeaderTextKeys(header)} text={dayjs(row[header]).format('D/M')} />
                      </StyledTd>
                    )
                  } else if (header === 'averageSessionTimeInMinutes') {
                    return (
                      <StyledTd key={header} isFutureDate={isFutureDate}>
                        <FigmaText textKey={getHeaderTextKeys(header)} text={Math.round(row[header]).toString()} />
                      </StyledTd>
                    )
                  } else if (header === 'countOfPaidSessions' && selectedDataType === 'count') {
                    return (
                      <StyledTd
                        key={header}
                        textValue={row['countOfPaidSessions'].toString()}
                        isFutureDate={isFutureDate}
                      >
                        <FigmaText textKey={getHeaderTextKeys(header)} text={row['countOfPaidSessions'].toString()} />
                      </StyledTd>
                    )
                  } else if (header === 'countSessionsEvOffense' && selectedDataType === 'count') {
                    return (
                      <StyledTd
                        key={header}
                        textValue={row['countSessionsEvOffense'].toString()}
                        isFutureDate={isFutureDate}
                      >
                        <FigmaText
                          textKey={getHeaderTextKeys(header)}
                          text={row['countSessionsEvOffense'].toString()}
                        />
                      </StyledTd>
                    )
                  } else if (header === 'countSessionsDisabledOffense' && selectedDataType === 'count') {
                    return (
                      <StyledTd
                        key={header}
                        textValue={row['countSessionsDisabledOffense'].toString()}
                        isFutureDate={isFutureDate}
                      >
                        <FigmaText
                          textKey={getHeaderTextKeys(header)}
                          text={row['countSessionsDisabledOffense'].toString()}
                        />
                      </StyledTd>
                    )
                  } else if (header === 'countSessionsMarkedOffense' && selectedDataType === 'count') {
                    return (
                      <StyledTd
                        key={header}
                        textValue={row['countSessionsMarkedOffense'].toString()}
                        isFutureDate={isFutureDate}
                      >
                        <FigmaText
                          textKey={getHeaderTextKeys(header)}
                          text={row['countSessionsMarkedOffense'].toString()}
                        />
                      </StyledTd>
                    )
                  } else if (header === 'countOfPaidSessions' && selectedDataType === 'value') {
                    return (
                      <StyledTd
                        key={header}
                        textValue={row['totalAmountOfPaidSessions'].toFixed(0).toString()}
                        isFutureDate={isFutureDate}
                      >
                        <FigmaText
                          textKey={getHeaderTextKeys(header)}
                          text={row['totalAmountOfPaidSessions'].toFixed(0).toString()}
                        />
                      </StyledTd>
                    )
                  } else if (header === 'countSessionsEvOffense' && selectedDataType === 'value') {
                    return (
                      <StyledTd
                        key={header}
                        textValue={row['totalAmountOfEvOffenseSessions'].toFixed(0).toString()}
                        isFutureDate={isFutureDate}
                      >
                        <FigmaText
                          textKey={getHeaderTextKeys(header)}
                          text={row['totalAmountOfEvOffenseSessions'].toFixed(0).toString()}
                        />
                      </StyledTd>
                    )
                  } else if (header === 'countSessionsDisabledOffense' && selectedDataType === 'value') {
                    return (
                      <StyledTd
                        key={header}
                        textValue={row['totalAmountOfDisabledOffenseSessions'].toFixed(0).toString()}
                        isFutureDate={isFutureDate}
                      >
                        <FigmaText
                          textKey={getHeaderTextKeys(header)}
                          text={row['totalAmountOfDisabledOffenseSessions'].toFixed(0).toString()}
                        />
                      </StyledTd>
                    )
                  } else if (header === 'countSessionsMarkedOffense' && selectedDataType === 'value') {
                    return (
                      <StyledTd
                        key={header}
                        textValue={row['totalAmountOfMarkedOffenseSessions'].toFixed(0).toString()}
                        isFutureDate={isFutureDate}
                      >
                        <FigmaText
                          textKey={getHeaderTextKeys(header)}
                          text={row['totalAmountOfMarkedOffenseSessions'].toFixed(0).toString()}
                        />
                      </StyledTd>
                    )
                  } else if (
                    header === 'totalAmountOfPaidSessions' ||
                    header === 'totalAmountOfEvOffenseSessions' ||
                    header === 'totalAmountOfDisabledOffenseSessions' ||
                    header === 'totalAmountOfMarkedOffenseSessions'
                  ) {
                    return null
                  } else {
                    return (
                      <StyledTd key={header} isFutureDate={isFutureDate}>
                        <FigmaText textKey={getHeaderTextKeys(header)} text={row[header].toString()} />
                      </StyledTd>
                    )
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </StyledTable>
    </Box>
  )
}

const Spinner = styled(Box)`
  width: 50px;
  height: 50px;
  border: 8px solid lightgray;
  border-radius: 50%;
  border-top-color: ${Colors.brandPink};
  animation: spin 1s linear infinite;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: ${Spacings.m};
`

const StyledTh = styled.th`
  padding: ${Spacings.xs};
  text-align: center;
`

const StyledTd = styled.td<{ textValue?: string; isFutureDate?: boolean }>`
  padding: ${Spacings.xs};
  text-align: center;

  span {
    color: ${(props) =>
      ((props?.textValue && props?.textValue === '0') || props?.isFutureDate) && Colors.gray400} !important;
  }
`

export default memo(SessionOverviewTable)
