import { z } from 'zod'

export const SiteSessionLogicSchema = z.strictObject({
  validRecordCount: z.number().optional(), // used in autoEndedJob. If to foew camera events then mark session as FALSE.
  autoCloseGapTimeMinutes: z.number().optional(), // used in autoEndedJob
  validNationalPlateWeightIncreaseRatio: z.number().optional(), // used in the competition to disqualify plates that are not according to national plate system
  startNewSessionGapTimeSeconds: z.number().optional(), // 1 min for <site>, 3 min for garage, its a way to not start new sessions, used when ending sessions in getParkingSession fure function.
  enableRequireSlotCameraEventForValidSession: z.boolean().optional() // deprecated
})

export type SiteSessionLogic = z.infer<typeof SiteSessionLogicSchema>
