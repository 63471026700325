/// <reference types="vite-plugin-svgr/client" />
import carImg from '@my-drifter/assets/images/car.png'
export const car = carImg
import carOffenseImg from '@my-drifter/assets/images/carOffense.png'
export const carOffense = carOffenseImg
import homeLandingAppStoreImg from '@my-drifter/assets/images/homeLandingAppStore.svg?react'
export const homeLandingAppStore = homeLandingAppStoreImg
import pngGooglePlayImg from '@my-drifter/assets/images/pngGooglePlay.png'
export const pngGooglePlay = pngGooglePlayImg
import successIconImg from '@my-drifter/assets/images/successIcon.svg?react'
export const successIcon = successIconImg
import drifterLogoDarkImg from '@my-drifter/assets/images/drifterLogoDark.svg?react'
export const drifterLogoDark = drifterLogoDarkImg
import checkIconOutlinedImg from '@my-drifter/assets/images/checkIconOutlined.svg?react'
export const checkIconOutlined = checkIconOutlinedImg
