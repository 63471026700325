import { createContext, ReactNode, useContext, useReducer } from 'react'

type State = {
  token: string | null
  sessionId: string | null
}

type Action =
  | { type: 'SET_LOGIN'; payload: { token: string } }
  | { type: 'SET_LOGOUT' }
  | { type: 'SET_SESSION_ID'; payload: { sessionId: string } }

const initialState: State = {
  token: localStorage.getItem('token') || null,
  sessionId: localStorage.getItem('sessionId' || null)
}

type AuthContextType = {
  state: State
  setLogin: (token: string) => void
  setLogout: () => void
  setSessionId: (sessionId: string) => void
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

const authReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_LOGIN':
      localStorage.setItem('token', action.payload.token)
      return {
        ...state,
        token: action.payload.token
      }
    case 'SET_LOGOUT':
      localStorage.removeItem('token')
      return {
        ...state,
        token: null
      }
    case 'SET_SESSION_ID':
      localStorage.setItem('sessionId', action.payload.sessionId)
      return {
        ...state,
        sessionId: action.payload.sessionId
      }
    default:
      return state
  }
}

type Props = {
  children: ReactNode
}

export const AuthProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(authReducer, initialState)

  const setLogin = (token: string) => {
    dispatch({ type: 'SET_LOGIN', payload: { token } })
  }

  const setLogout = () => {
    dispatch({ type: 'SET_LOGOUT' })
  }

  const setSessionId = (sessionId: string) => {
    dispatch({ type: 'SET_SESSION_ID', payload: { sessionId } })
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        setLogin,
        setLogout,
        setSessionId
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
