import 'react-calendar/dist/Calendar.css'

import styled from '@emotion/styled'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { memo, useEffect, useRef, useState } from 'react'
import Calendar from 'react-calendar'
import { OnClickFunc } from 'react-calendar/dist/cjs/shared/types'

import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'

import { Colors } from '@pure/assets/Colors'

import ZIndices from '@web-js/enums/Zindices'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'

type Props = {
  value?: Date
  onChange?: OnClickFunc
}

const CalendarInput = ({ value, onChange }: Props) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const onClickMonth = (value, event) => {
    onChange && onChange(value, event)
    setDropdownIsOpen(false)
  }

  return (
    <DropdownContainer ref={dropdownRef} fullWidth position="relative">
      <DropdownHeader
        fullWidth
        direction="row"
        align="center"
        justify="space-between"
        fullPadding
        spacing={Spacings.xs}
        onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
      >
        {value && `${value.getFullYear()}-${String(value.getMonth() + 1).padStart(2, '0')}`}
        <ExpandMore />
      </DropdownHeader>
      <DropdownContent isOpen={dropdownIsOpen} fullWidth align="center" zIndex={ZIndices.modal}>
        <Calendar
          view="year"
          value={value}
          onClickMonth={onClickMonth}
          className="react-calendar"
          minDate={new Date('2024-06')}
        />
      </DropdownContent>
    </DropdownContainer>
  )
}

const DropdownContainer = styled(Box)`
  max-width: 25%;
  user-select: none;
  font-family: 'Inter';
`

const DropdownHeader = styled(Box)`
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: ${BorderRadiusesPx.minimum};
`

const DropdownContent = styled(Box)`
  top: 100%;
  position: absolute;
  background-color: ${Colors.white};
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px ${Colors.black};
  border-radius: ${BorderRadiusesPx.minimum};
  display: ${(props: { isOpen: boolean }) => (props.isOpen ? 'block' : 'none')};

  .react-calendar {
    border: none;
    width: 100%;
  }
`

export default memo(CalendarInput)
