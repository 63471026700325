import { DisabilityPermit, Permit } from '@contracts/types/Permit'

import { Language } from '@pure/emu/Antiloop'
import { components } from '@pure/swagger/DrifterTypes'

export type CreateUserRequestBase = {
  name?: string
  locale?: Language
  email?: string
  phone: string
  uid?: string
  plates?: string[]
}

export type CreateUserRequest = CreateUserRequestBase & {
  userId?: string
}

export type HikerUser = CreateUserRequest &
  DrifterUserObject & {
    siteIds?: string[]
    role?: HikerUserRole
    stripeId?: string
    email?: string
    defaultPaymentMethodId?: string
    expoPushNotificationToken?: string
    permits?: {
      [permitId: string]: Permit
    }
    disabledUser?: boolean
    disabilityPermit?: DisabilityPermit
  }

export type DrifterUser = components['schemas']['user']
export type DrifterUserObject = components['schemas']['user-object']

export enum HikerUserRole {
  ADMIN = 'ADMIN',
  VIEWER = 'VIEWER'
}
