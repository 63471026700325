import SevenNineEightThreeSvg from './7983.svg'
export const SevenNineEightThree = SevenNineEightThreeSvg
import SevenNineEightEightSvg from './7988.svg'
export const SevenNineEightEight = SevenNineEightEightSvg
import SevenNineEightNineSvg from './7989.svg'
export const SevenNineEightNine = SevenNineEightNineSvg
import SevenNineNineZeroSvg from './7990.svg'
export const SevenNineNineZero = SevenNineNineZeroSvg
import SevenNineNineOneSvg from './7991.svg'
export const SevenNineNineOne = SevenNineNineOneSvg
import SevenNineNineTwoSvg from './7992.svg'
export const SevenNineNineTwo = SevenNineNineTwoSvg
import SevenNineNineFiveSvg from './7995.svg'
export const SevenNineNineFive = SevenNineNineFiveSvg
import SevenNineNineEightSvg from './7998.svg'
export const SevenNineNineEight = SevenNineNineEightSvg
import EightThousandSvg from './8000.svg'
export const EightThousand = EightThousandSvg
import EightThousandThreeSvg from './8003.svg'
export const EightThousandThree = EightThousandThreeSvg
import EightThousandFourSvg from './8004.svg'
export const EightThousandFour = EightThousandFourSvg
