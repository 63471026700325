import { z } from 'zod'

export type SiteFeatures = z.infer<typeof SiteFeaturesSchema>

export const SiteFeaturesSchema = z.object({
  enableNoPermitInDisabledSlotOffenseFee: z.boolean().optional(),
  enableMarkedSlotOffenseFee: z.boolean().optional(),
  enableQuickStopOffenseFee: z.boolean().optional(),
  enableFossilCarInEVSlotOffenseFee: z.boolean().optional()
})

export type SiteOffenseFees = z.infer<typeof SiteOffenseFeesSchema>

export const SiteOffenseFeesSchema = z.object({
  maxTotalParkingFee: z.number().optional(),
  noPermitInDisabledSlotOffenseFee: z.number().optional(),
  markedSlotOffenseFee: z.number().optional(),
  fossilCarInEVSlotOffenseFee: z.number().optional(),
  quickStopOffenseFee: z.number().optional()
})
