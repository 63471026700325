import { z } from 'zod'

export const PermitValiditySchema = z.object({
  dayOfWeek: z.number().min(1).max(7),
  startHour: z.number().min(1).max(24),
  endHour: z.number().min(1).max(24)
})

export const PermitTemplateValidityPeriodsSchema = z.array(PermitValiditySchema)

export const PermitTemplateBaseSchema = z.object({
  id: z.string(),
  siteId: z.string(),
  name: z.string(),
  price: z.number(),
  validityPeriods: PermitTemplateValidityPeriodsSchema.optional(),
  featureTooglePublicPermit: z.boolean().optional(),
  featureToogleAllCarsCanUseEV: z.boolean().optional(),
  featureToogleMaxConcurrentPermit: z.boolean().optional(),
  featureToogleEVCarsCanUseEV: z.boolean().optional(),
  code: z.string().optional(),
  maxNumberOfConcurrentParkedVehicles: z.number().optional(), // max number of cars that can be parked at the same time
  additionalConditions: z.string().optional()
})

export const PermitTemplateSchema = PermitTemplateBaseSchema.extend({
  id: z.string()
})

export const UpsertPermitTemplateRequestSchema = PermitTemplateBaseSchema.extend({
  id: z.string().optional()
})

export type PermitTemplate = z.infer<typeof PermitTemplateSchema>

export type UpsertPermitTemplateRequest = z.infer<typeof UpsertPermitTemplateRequestSchema>

export type PermitValidityPeriod = z.infer<typeof PermitValiditySchema>
